import React from "react";

export default function Guarantee() {
	return(
	<div className="small-12 medium-10 large-8 medium-offset-1 large-offset-2 cell" style={{marginBottom: "1.25rem"}}>
		<div className="grid-x grid-margin-x" style={{alignItems: "center", justifyContent: "center", margin: 0, backgroundColor: "#eaeaea", padding: "1rem"}}>
			<div className="small-4 medium-3 large-2 cell">
				<img src="/assets/images/NoExcuseGuarantee-Transparent.png" alt="No Excuse Guarantee image" />
			</div>
			<p className="small-8 medium-9 large-10 cell" style={{marginBottom: 0}}>You're protected by a full, No Excuse, no-questions 100% Money Back guarantee.</p>
		</div>
	</div>
	)
}