import React from "react";
import {UnitData} from "../data/unitData";
import UnpackUnitChildren from '../functions/unpackUnitChildren';
import {Link} from "gatsby";




export default function SetupHomePageUnitLinks(props) {
	let currentState = props.state;
    return(
        <div id="content" className="grid-x grid-margin-x mainContainer">
          <h3 className="small-12" style={{justifyContent: "center", alignItems:"center", textAlign: "center"}}>
            Active Gear Stores
          </h3>
          <div className="small-12 cell">
            <div 
              id="home-page-unit-container"
              className={(currentState.numberOfChildUnits < 3 && currentState.numberOfChildUnits >0) 
                          ? "grid-x grid-margin-x small-up-" + currentState.numberOfChildUnits 
                          : (currentState.numberOfChildUnits < 6 && currentState.numberOfChildUnits > 0 )
                            ? "grid-x grid-margin-x small-up-3 medium-up-4 large-up-" + currentState.numberOfChildUnits
                            : "grid-x grid-margin-x small-up-3 medium-up-4 large-up-6"
                        } 
              style={{justifyContent: 'center'}}
            >
              {currentState.currentWebsite.base_gear &&
              <div className="cell small-6 link-container main-store">
                <Link id="base_gear-link" className="link-label" to={"/stores/base_gear"}>        

                    <img src={"/assets/images/unitInsiginias/"+UnitData[currentState.currentWebsite.base_gear].insignia} />
                    <p >{UnitData[currentState.currentWebsite.base_gear].name} gear</p>

                </Link>
              </div>
              }

              <div className="cell small-6 link-container main-store">
                
                <Link id={currentState.topLevelUnitInfo.id + "-link"} className="link-label grid-y" to={"/stores/"}>
                  <div className="cell" >
                    <img src={"/assets/images/unitInsiginias/" + currentState.topLevelUnitInfo.insignia}  />  
                  </div> 
                  <p className="cell">{currentState.topLevelUnitInfo.shortName}{currentState.topLevelUnitInfo.motto ? (' "'+currentState.topLevelUnitInfo.motto)+'"' : ""}</p>
                </Link>
              </div>
              

              {currentState.topLevelUnitInfo.unitChildren && currentState.topLevelUnitInfo.unitChildren.map((unitChild, index) => {
                  if (UnitData[unitChild].shopifyUnitCollectionId || UnitData[unitChild].shopifyCivilianCollectionId)
                  return (<UnpackUnitChildren showImages index={index} key={unitChild} unitChild={unitChild} inheritProps={currentState.paramsAsObj} img={"/assets/images/unitInsiginias/"+UnitData[unitChild].insignia}/>)
                })
              } 
            </div>
          </div>
          <div className="small-12" >
            <div className="grid-x grid-margin-x" style={{justifyContent: "center", alignItems:"center", textAlign: "center"}}>
              <p className="small-12 medium-shrink cell">Don't see your Battery/Company?</p>
              <Link className="small-12 medium-shrink cell" to="intake"><button className="button">Request gear</button></Link>
            </div>
          </div>
      </div>
    )
  }