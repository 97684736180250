import React, {Component} from "react";
import {Link} from "gatsby";
import HeaderFooter from '../components/headerFooter';
import UnpackUnitChildren from '../functions/unpackUnitChildren';
import {UnitData} from "../data/unitData";
import Guarantee from '../components/guarantee';
import {websiteContent} from '../data/websiteContent';
import {GetCurrentWebsite} from '../functions/getCurrentWebsite';
import SetupHomePageUnitLinks from '../components/setupHomePageUnitLinks';


class Home extends React.Component {
  constructor(props) {
    super(props); 

    let paramsAsObj = {'params' : { '*' : this.props.path}}

    let currentWebsite = GetCurrentWebsite(); // Function to check domain and return templated info based on which domain a user has visited. (CURRENTLY IS HARD CODED)

    let topLevelUnitInfo = UnitData[currentWebsite.top_level_unit_id];
    let numChildren;
    try {numChildren = topLevelUnitInfo.unitChildren.length}catch(e){numChildren=0};


    this.state = {
      params: paramsAsObj,
      numberOfChildUnits: numChildren,
      currentWebsite: currentWebsite,
      topLevelUnitInfo: topLevelUnitInfo,
    }
  }

  componentDidMount(){
    let bgImg = null;
    if (!bgImg){
      bgImg = "url('/assets/images/" + this.state.currentWebsite.hero_image;
    }

    this.setState({
      isClient: true, 
      bgImg: {backgroundImage: bgImg}
    }) // use this.state.isClient to re-render items that fail hydration
  }



  heroHeader = () => {
    let topLevelUnitMotto = "";
    if (this.state.topLevelUnitInfo.motto){
      topLevelUnitMotto = this.state.topLevelUnitInfo.motto;
    }
    return(
      <div id="" className="grid-x grid-padding-x" style={{minHeight:"0"}}>          
                <div id="hero-container-inner" className="small-12 cell" style={this.state.bgImg} >
                  
                   <div id="" className="grid-x" style={{textAlign:"left", zIndex: 2}} >
                      <div className="small-12 medium-8 cell">
                        <h1 className="h2 cell">{topLevelUnitMotto ? "The \"" + topLevelUnitMotto + "\"" + " family " : this.state.topLevelUnitInfo.name} Unit and Civilian gear stores.</h1> 
                        
                      </div>
                      <div className="cell small-12 medium-8" style={{marginBottom: "7.5vh"}}>
                          <p className="h4">
                            The "easy button" for military fundraising through custom "way-better-than-military-grade" apparel and gear.
                            {/*
                            <br/><br/>
                            For {topLevelUnitMotto ? topLevelUnitMotto.slice(-1) === "s" ? topLevelUnitMotto + " " : topLevelUnitMotto + "s "
                                                             : this.state.topLevelUnitInfo.name ?  this.state.topLevelUnitInfo.name + " members" : "soldiers "} 
                            and their families click "Buy Gear" find and purchase your custom, high-quality gear that looks so good, other Units get jealous.
                            */}
                            <br/> <br/>Powered by Emblem Athletic.
                          </p>
                      </div>
                      <div className="small-12 medium-8 large-6 cell">
                        <div id="main-pg-cta" className="grid-x grid-margin-x">
                          <Link className="button small-12 medium-5 cell" to="/stores" style={{fontWeight: "900", marginTop: "1rem", marginBottom: "1rem", backgroundColor: "#C0022E", color: "#fff", border: "1px solid #1F2830"}}>Buy gear</Link>
                          <Link className="button small-12 medium-auto cell" to="/intake" style={{fontWeight: "900", marginTop: "1rem", marginBottom: "1rem", backgroundColor:"#1F2830", color: "#fff"}}>Fund your next BBQ/SFRG event</Link>
                          
                        </div>
                      </div>

                      
                    </div>

                </div>
      </div>
    )
  }


  unitLinks = () => {
    return(
        <div id="content" className="grid-x grid-margin-x mainContainer">
          <h2 className="small-12" style={{justifyContent: "center", alignItems:"center", textAlign: "center"}}>
            Active gear stores
          </h2>
          <div className="small-12 cell">
            <div 
              id="home-page-unit-container"
              className={(this.state.numberOfChildUnits < 3 && this.state.numberOfChildUnits >0) 
                          ? "grid-x grid-margin-x small-up-" + this.state.numberOfChildUnits 
                          : (this.state.numberOfChildUnits < 6 && this.state.numberOfChildUnits > 0 )
                            ? "grid-x grid-margin-x small-up-3 medium-up-4 large-up-" + this.state.numberOfChildUnits
                            : "grid-x grid-margin-x small-up-3 medium-up-4 large-up-6"
                        } 
              style={{justifyContent: 'center'}}
            >
              {this.state.currentWebsite.base_gear &&
              <div className="cell small-6 link-container main-store">
                <Link id="base_gear-link" className="link-label" to={"/stores/base_gear"}>        

                    <img src={"/assets/images/unitInsiginias/"+UnitData[this.state.currentWebsite.base_gear].insignia} />
                    <p >{UnitData[this.state.currentWebsite.base_gear].name} gear</p>

                </Link>
              </div>
              }

              <div className="cell small-6 link-container main-store">
                
                <Link id={this.state.topLevelUnitInfo.id + "-link"} className="link-label grid-y" to={"/stores/"}>
                  <div className="cell" >
                    <img src={"/assets/images/unitInsiginias/" + this.state.topLevelUnitInfo.insignia}  />  
                  </div> 
                  <p className="cell">{this.state.topLevelUnitInfo.shortName}{this.state.topLevelUnitInfo.motto ? (' "'+this.state.topLevelUnitInfo.motto)+'"' : ""}</p>
                </Link>
              </div>
              

              {this.state.topLevelUnitInfo.unitChildren.map((unitChild, index) => {
                  if (UnitData[unitChild].shopifyUnitCollectionId || UnitData[unitChild].shopifyCivilianCollectionId)
                  return (<UnpackUnitChildren showImages index={index} key={unitChild} unitChild={unitChild} inheritProps={this.state.paramsAsObj} img={"/assets/images/unitInsiginias/"+UnitData[unitChild].insignia}/>)
                })
              } 
            </div>
          </div>
          <div className="small-12" >
            <div className="grid-x grid-margin-x" style={{justifyContent: "center", alignItems:"center", textAlign: "center"}}>
              <p className="small-12 medium-shrink cell">Don't see your Battery/Company?</p>
              <Link className="small-12 medium-shrink cell" to="intake"><button className="button">Request gear</button></Link>
            </div>
          </div>
      </div>
    )
  }

  differentiators = () => {
    const salesText = [
      {
        headline: "Fundraising on autopilot",
        supportingText: `
          Whether you are a CFRR, Commander, CSM or run an SFRG, MWR, or run other morale-focused fund - you know fundraising is (at best) painful and tedious. \n \n Ditch the pain: Emblem creates storefronts, connects seamlessly to your organization's bank account, and automatically sends you up to $5 for every item sold. No risk, no upkeep, and no hassle. Just set it, forget it, and focus on hosting the best events for your soldiers.
        `,
        img: true,
        video: false,
        mediaSrc: "BitchinBBQMeter.jpg"
      },
      {
        headline: "Won't crack or fade",
        supportingText: `
          Unlike your typical screen-printed-on-an-80-grit-cotton shirt, gear from us has designs inked directly into the fabric fibers for a soft-feel, never-peel look that's guaranteed to bring out your best.
        `,
        img: true,
        video: false,
        mediaSrc: "screen-print-vs-emblem.png"
      },
      {
        headline: "Made to order",
        supportingText: `All gear starts as a roll of fabric until someone clicks "buy". From there the design is printed, cut, sewn, and shipped. This means no minimums, no bulk requirements, and unlimited design ideas (without tons of waste.)`,
        img: true,
        video: false,
        mediaSrc: "PrintingProcess1.jpg"
      },
      {
        headline: `Proudly not "military grade"`,
        supportingText: "By flipping the typical apparel company's model, our made-to-order process means we can give you the highest grade fabrics that exist. Ones with a stupid-soft feel that will also last a lifetime. It's a little slower than screen prints... just like Alexander's Steakhouse is a little slower than McDonald's.",
        img: true,
        video: false,
        mediaSrc: "PrintingProcess3.jpg"
      },
      {
        headline: `100%, no-questions No Excuse guarantee`,
        supportingText: "No matter what happens, we've got your six. Don't like it? Refunded. Doesn't fit? Refunded. Fat-finger bought the wrong size? Yes, we refunded someone for that, too.",
        img: true,
        video: false,
        mediaSrc: "no-excuse-guarantee.jpg"
      }
    ]

    return(
      <div id="differentiator-container" className="mainContainer grid-x grid-margin-x" >
        <div className="small-12 medium-10 large-10 medium-offset-1 large-offset-1 cell">
          <h2 className="text-center">Not your typical Unit gear</h2>
          
          {salesText.map((section, index) => {
            let stdContainerStyles = "salesSection grid-x grid-margin-x";
            if (salesText){if(salesText.length === index + 1){stdContainerStyles = stdContainerStyles + " last"}}
            const isAnEvenIndexNumber = (index%2 === 0);

            return(
              <div key={section.headline} className={isAnEvenIndexNumber ? stdContainerStyles + " even" : stdContainerStyles+ " odd"}>
                <div className="media small-order-2 small-12 medium-6 large-4 cell">
                  {section.img && !section.video && <img src={"/assets/images/" + section.mediaSrc} />}
                  {section.video && !section.img && <video src={"/assets/videos/" + section.mediaSrc} />}
      
                </div>
                <div className="text small-order-1 small-12 medium-auto large-7 cell">
                  <h3>{section.headline}</h3>
                  <p>{section.supportingText}</p>
                </div>
        
              </div>
            )
          })}
        </div>
      </div>
    )
  }


  aboutTheCeo = () => {
    const mikeImg = <img src="/assets/images/Mike+Nemeth+Emblem+Athletic.jpeg" alt="Mike Nemeth photo, the founder of Emblem Athletic"/>

    return (
      <>
        <div className="grid-x grid-margin-x ">
          <h2 className="small-12 cell text-center">A veteran working to improve esprit de corps</h2>
          
          <div className="small-12 cell container-with-floating-image">
            {mikeImg}
            <div className="text">
              <p>Have you ever been excited about a $7 unit shirt? I wasn't - the first time I had a 'mandatory fun' shirt I wrote a satire about how useless they are for improving morale.</p>
              <p>I graduated from West Point, but didn't serve as long as I expected because of an injury. Since then I've been looking for ways to contribute and I've had a front-row seat to the struggles of my friends in a variety of leadership roles.</p>
              <p>What they want is to build morale, create strong teams, and engaged families.</p>

              <p>But the tools they're given? 80-grit Unit shirts printed in Bob's basement... or massive minimums.</p>
              
              <p>And SFRC fundraising? Some have picked cans for recycling money, just to have a decent BBQ.</p>

              <p>I knew there had to be a better way. Our team built an all new system from scratch to make Emblem the easiest way to order custom gear for your military unit of any size (including rec sports teams and leagues). It's not just a t-shirt to me and our team - we know how important it is to get it right.</p>

              <p>Work with us and you'll get phenomenal, made-from-scratch gear; no minimums; an army of designers creating branded gear for you; forever-active storefronts; and SFRG fundraising on autopilot.</p>

              <p>Helping you make your team look great is what drives our team every day.</p>
              <p>
                Very respectfully,<br/>
                — Mike Nemeth, CEO
              </p>
            </div>
          </div>

        </div>
      </>
    )
  }




  render(){
      return (
        <>
          
          <HeaderFooter>
  
            <div className="grid-container fluid">
              {this.heroHeader()}
              {/*{this.unitLinks(this.state)}*/}
              <SetupHomePageUnitLinks state={this.state} key={this.state.isClient} />
              {this.differentiators()}
              <div className="grid-x grid-margin-x mainContainer">
                <Guarantee />
              </div>
              {this.aboutTheCeo()}


            </div>
  
          </HeaderFooter>
        </>
      )
    }
  }


export default Home;











